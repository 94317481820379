.backgammon-container {
    background: linear-gradient(180deg, #1a1a1a, #283c86);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100vh;
    width: 100vw;
    max-width: 100vw;
    color: #ffffff;
    font-family: 'Arial', sans-serif;
    position: relative;
    overflow: hidden;
    touch-action: none;
}

/* Opponent Wrapper */
.backgammon-opponent-wrapper {
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 0 15px;
    box-sizing: border-box;
}

/* Opponent Area */
.backgammon-opponent-area {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 12px 14px;
    width: 100%;
    max-width: 500px;
    background: linear-gradient(180deg, #3b4c6b 0%, #1d2b4f 77.5%);
    border: 2px solid #8c5e01;
    box-shadow: inset 0 0 12px 5px rgba(0, 0, 0, 0.6);
    border-radius: 25px;
    box-sizing: border-box;
}

/* Opponent Avatar */
.backgammon-opponent-avatar {
    width: 60px;
    height: 60px;
    background-color: #d9d9d9;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
    font-size: 32px;
    font-weight: bold;
    color: #464646;
    text-transform: uppercase;
    overflow: hidden;
}

/* Opponent Info */
.backgammon-opponent-info {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 10px;
}

/* Opponent Name and Flag */
.backgammon-name-and-flag {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}

.backgammon-opponent-name {
    font-size: 15px;
    font-weight: bold;
    color: #ffffff;
    margin-right: 5px;
    max-width: 80px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.backgammon-country-flag {
    width: 18px;
    height: 18px;
    margin-left: 5px;
    vertical-align: middle;
}

/* Opponent Score */
.backgammon-opponent-score {
    font-size: 14px;
    color: #ffde59;
    margin-bottom: 5px;
}

/* Opponent Checkers Borne Off */
.backgammon-opponent-checkers {
    font-size: 14px;
    color: #4caf50;
}

/* Timer Circle */
.backgammon-timer-circle {
    position: absolute;
    top: -5px;
    right: -5px;
    width: 30px;
    height: 30px;
}

.base-circle {
    fill: none;
    stroke: #333;
    stroke-width: 4;
}

.timer-circle {
    fill: none;
    stroke-width: 4;
    stroke-dasharray: 283;
    stroke-dashoffset: 0;
    transition: stroke-dashoffset 1s linear;
}
























.backgammon-board-wrapper {
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 600px;
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
}

.backgammon-board-container {
    position: relative; /* This allows us to position points inside the container */
    width: 340px; /* Board container width */
    height: 400px; /* Board container height */
    background: url('/backgammonAssets/board.png') no-repeat center center;
    background-size: cover;
    box-shadow: 0 0 14px 10px rgba(0,0,0,0.4);
    border-radius: 13px;
    z-index: 1;
}

.backgammon-point {
    position: absolute; /* Points are positioned inside the board container */
    width: 26px;  /* Width for each point */
    height: 120px;  /* Height for each point */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    z-index: 1;
}


/* Updated Checker Styling with PNG images */
.backgammon-checker {
    width: 22px;  /* Resize to fit inside the point */
    height: 22px;
    position: absolute;
    bottom: 0;  /* Start at the bottom of the point */
    transition: top 0.3s ease, bottom 0.3s ease;
}

/* White Checker */
.backgammon-checker.white {
    background: url('/backgammonAssets/checker-white.png') no-repeat center center;
    background-size: contain;
}

/* Black Checker */
.backgammon-checker.black {
    background: url('/backgammonAssets/checker-black.png') no-repeat center center;
    background-size: contain;
}

/* Adjust for stacked checkers: */
.backgammon-checker.stacked {
    z-index: 2; /* Stack the next checker on top */
    bottom: 10px;  /* Adjust to stack above previous checkers */
}


.backgammon-dice-container {
    position: absolute;
    bottom: 184px;
    display: flex;
    gap: 10px;
}

.player-dice {
    left: 64.2%; /* Right half of the board for the current player */
}

.opponent-dice {
    left: 13.7%; /* Left half of the board for the opponent */
}

.backgammon-dice1,
.backgammon-dice2 {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    border-radius: 8px;
}

.backgammon-OpponentDice1,
.backgammon-OpponentDice2 {
    width: 32px;
    height: 32px;
    left: 20%;
    transform: translateX(-20%);
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    border-radius: 8px;
}

.backgammon-roll-button {
    position: absolute;
    bottom: 178px;
    cursor: pointer;
}

.backgammon-bear-off {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.game-end-popup {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.85);
    color: #fff;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
    text-align: center;
    font-size: 18px;
}



















/* Player Wrapper */
.backgammon-player-wrapper {
    position: absolute;
    bottom: 50px;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 0 15px;
    box-sizing: border-box;
}

/* Player Area */
.backgammon-player-area {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 12px 14px;
    width: 100%;
    max-width: 500px;
    background: linear-gradient(180deg, #3b4c6b 0%, #1d2b4f 77.5%);
    border: 2px solid #8c5e01;
    box-shadow: inset 0 0 12px 5px rgba(0, 0, 0, 0.6);
    border-radius: 25px;
    box-sizing: border-box;
}

/* Player Avatar */
.backgammon-player-avatar {
    width: 60px;
    height: 60px;
    background-color: #d9d9d9;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
    font-size: 32px;
    font-weight: bold;
    color: #464646;
    text-transform: uppercase;
    overflow: hidden;
}

.backgammon-avatar-image {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
}

/* Player Info */
.backgammon-player-info {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

/* Player Name and Flag */
.backgammon-name-and-flag {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}

.backgammon-player-name {
    font-size: 15px;
    font-weight: bold;
    color: #ffffff;
    margin-right: 5px;
    max-width: 80px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.backgammon-country-flag {
    width: 18px;
    height: 18px;
    margin-left: 5px;
    vertical-align: middle;
}

/* Player Score */
.backgammon-player-score {
    font-size: 14px;
    color: #ffde59;
    margin-bottom: 5px;
}

/* Player Checkers Off */
.backgammon-player-checkers {
    font-size: 14px;
    color: #4caf50;
}

/* Timer Circle */
.backgammon-timer-circle {
    position: absolute;
    top: -5px;
    right: -5px;
    width: 30px;
    height: 30px;
}

.backgammon-base {
    fill: none;
    stroke: #333;
    stroke-width: 4;
}

.backgammon-timerP {
    fill: none;
    stroke-width: 4;
    stroke-dasharray: 283;
    stroke-dashoffset: 0;
    transition: stroke-dashoffset 1s linear;
}















/* Apply transform animation for the dice movement */
.shift-die {
    animation: moveDice 0.8s ease forwards;
}

/* Define the movement animation */
@keyframes moveDice {
    to {
        transform: translate(var(--shift-x), var(--shift-y)); /* Use the custom properties set in JS */
    }
}


/* Animation for switching dice */
.switch-backgammon-dice {
    animation: switchDice 0.5s ease-out;
  }
  
  /* Keyframes for animating dice switch */
  @keyframes switchDice {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(var(--shift-x));  /* Will shift the dice */
    }
  }








